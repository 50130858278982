<template>
  <div
    v-if="(otherContracts.data && otherContracts.data.data.length > 0) || loggedUser.roles.includes('super-admin')"
    class="col-md-8"
  >
    <div
      class="card card--table"
    >
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="clearfix">
              <h3 class="float-start">
                <i
                  data-feather="layers"
                  class="w-icon-24"
                /> Others contracts
              </h3>
              <span
                v-if="loggedUser.roles.includes('super-admin')"
                class="float-end"
              > <!-- only admin -->
                <span
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Add other contract"
                >
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasAddOtherContract"
                    aria-controls="offcanvasAddOtherContract"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    @click="openUploadContract($event)"
                  ><i data-feather="plus" /></a>
                </span>
              </span>
            </div>
            <hr>
          </div>
        </div>
        <div
          v-if="loading"
          class="loading position-relative"
        >
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
        <div
          v-else
          class="table-responsive-sm overflow-scroll"
        >
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Type of contract</th>
                <th>Contract with:</th>
                <th>Begin date</th>
                <th>End date</th>
                <th>Authorization status</th>
                <th>Compatibility date</th>
                <!-- <th>Pluriocupation date</th> -->
                <th>File</th>
                <th
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="text-end"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(otherContract, index) in otherContracts.data.data"
                :key="index"
                class="h-57"
              >
                <td>{{ otherContract.type ? otherContract.type.name : otherContract.contract_type_name }}</td>
                <td>{{ otherContract.institution ? otherContract.institution.code : '---' }}</td>
                <td>{{ otherContract.begin_date }}</td>
                <td>{{ otherContract.end_date ? otherContract.end_date : 'Present' }}</td>
                <td v-html="otherContract.status ? otherContract.status.status_table : '---'" />
                <td>{{ otherContract.compatibility_date ? otherContract.compatibility_date : '---' }}</td>
                <!-- <td>{{ otherContract.plurioccupation_date ? otherContract.plurioccupation_date : '---' }}</td> -->
                <td>
                  <span
                    v-if="otherContract.main_file"
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="View file"
                  ><a
                    :href="otherContract.main_file.url"
                    target="_blank"
                    class="btn btn-icon rounded-circle btn-flat-dark"
                  ><i data-feather="file" /></a></span>
                </td>
                <td
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="text-end"
                >
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit"
                  ><a
                    href="#"
                    class="btn btn-icon rounded-circle btn-flat-dark"
                    @click="$router.push({name: 'researcher.administration.other-contracts.edit', params: { id: otherContract.id }})"
                  ><i data-feather="edit-3" /></a></span>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete"
                  ><a
                    href="#"
                    class="btn btn-icon rounded-circle btn-flat-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#modalDeleteWithInput"
                    @click="showDeleteModal(otherContract.id)"
                  ><i data-feather="trash-2" /></a></span>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="showingAll"
            class="card-body"
          >
            <div class="d-flex align-items-center justify-content-between">
              <b-pagination
                v-model="paginator.currentPage"
                :total-rows="otherContracts.total"
                :per-page="paginator.perPage"
                aria-controls="table"
                @change="changePage"
              />
            </div>
          </div>
          <div
            v-if="otherContracts.total > minValues"
            class="d-flex align-items-center justify-content-between"
          >
            <a
              class="btn btn-sm btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Display all blocks"
              @click="showAllData"
            >
              {{ showingAll ? 'Show less' : 'Show all' }} <i data-feather="eye" /></a>
          </div>
        </div>
      </div>
      <ItemInfoSheet :table-id="otherInfoSheet">
        <template #info-sheet-title>
          Add Other contract
        </template>
        <template
          #info-sheet
        >
          <div class="offcanvas-body">
            <div class="mb-1">
              <label
                for="nameseniorcall"
                class="form-label"
              >Type of contract</label>
              <v-select
                v-model="newOtherContract.type"
                label="name"
                :clearable="true"
                :options="types"
                :get-option-key="option => option.id"
              />
            </div>
            <div class="mb-1 form-group required">
              <label
                for=""
                class="form-label"
              >Document name</label>
              <input
                v-model="newOtherContract.name"
                required
                type="text"
                class="form-control"
              >
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Description</label>
              <textarea
                id=""
                v-model="newOtherContract.description"
                name=""
                cols="30"
                rows="3"
                class="form-control"
              />
            </div>
            <div class="mb-1">
              <label
                for="nameseniorcall"
                class="form-label"
              >Host institution</label>
              <v-select
                v-model="newOtherContract.institution"
                label="name"
                :clearable="true"
                :options="hosts"
                :get-option-key="option => option.id"
                @search="onSearch({ name: $event }, 'institutions/filter')"
              />
            </div>
            <div class="mb-1 form-group required">
              <label
                for=""
                class="form-label"
              >Begin date</label>
              <date-picker
                v-model="newOtherContract.begin_date"
                format="D MMM Y"
                value-type="format"
                class="w-100"
              />
            </div>
            <div class="mb-1 form-group required">
              <label
                for=""
                class="form-label"
              >Compatibility date</label>
              <date-picker
                v-model="newOtherContract.compatibility_date"
                format="D MMM Y"
                value-type="format"
                class="w-100"
              />
            </div>
            <div class="mb-1">
              <label
                for="nameseniorcall"
                class="form-label"
              >Authoritation status</label>
              <v-select
                v-model="newOtherContract.status"
                label="status"
                :options="statuses"
                :get-option-key="option => option.id"
              />
            </div>
            <hr>
            <div class="mb-1 col-12 form-group required">
              <label
                :for="`formFile-contract`"
                class="form-label"
              >Upload file</label>
              <input
                :id="`formFile-contract`"
                :ref="`file-contract`"
                class="form-control"
                type="file"
              >
            </div>
          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              type="button"
              class="btn btn-success mb-1 d-grid w-100"
              @click="addContract"
            >
              Upload contract
            </button>
          </div>
        </template>
      </ItemInfoSheet>
    </div>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    BPagination,
    ItemInfoSheet,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      loading: true,
      paginator: {
        perPage: 10,
        currentPage: 1,
      },
      showingAll: false,
      minValues: 3,
      maxValues: 10,
      otherInfoSheet: 2,
      newOtherContract: {
        files_: [],
      },
    }
  },
  computed: {
    ...mapGetters({
      otherContracts: 'users/otherContracts',
      loggedUser: 'auth/admin',
      types: 'typeContracts/items',
      hosts: 'institutions/institutions',
      statuses: 'status/statuses',
      user: 'users/user',
    }),
  },
  async mounted() {
    await this.$store.dispatch('users/fetchUserOtherContracts', { id: this.user.id })
    await this.$store.dispatch('typeContracts/fetch')
    await this.$store.dispatch('status/filterStatus', 'Other contracts')
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    this.loading = false
  },
  methods: {
    async showDeleteModal(id) {
      Vue.swal({
        title: 'Are you sure?',
        text: 'This action cannot be reversed',
        icon: 'warning',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        closeOnConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/other-contracts/${id}`)
          Vue.swal('Deleted successfully!', '', 'success').then(() => {
            this.$store.dispatch('users/fetchUserOtherContracts', { id: this.user.id })
            // eslint-disable-next-line no-undef
            feather.replace({
              width: 14,
              height: 14,
            })
          })
        }
      })
    },
    openUploadContract(event) {
      event.stopPropagation()
      this.$store.dispatch('modals/toggleTableClicked', this.otherInfoSheet)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async changePage(page) {
      this.loading = true
      await this.$store.dispatch('users/fetchUserOtherContracts', { id: this.user.id, page, perPage: this.maxValues })
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    async showAllData() {
      this.showingAll = !this.showingAll
      this.loading = true
      if (this.showingAll) {
        this.paginator.currentPage = 1
        await this.$store.dispatch('users/fetchUserOtherContracts', { id: this.user.id, page: this.paginator.currentPage, perPage: this.maxValues })
      } else {
        await this.$store.dispatch('users/fetchUserOtherContracts', { id: this.user.id, perPage: this.minValues })
      }
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    async addContract() {
      const file = this.$refs['file-contract'].files[0]
      if (file) {
        // eslint-disable-next-line no-underscore-dangle
        this.newOtherContract.files_.push({
          name: this.newOtherContract.name,
          original_name: file.name,
          description: this.newOtherContract.description,
          weight: (file.size / 1024 / 1024).toFixed(2),
          extension: file.type,
          file,
          to: ['All'],
        })
        this.newOtherContract.user = { id: this.user.id }
        try {
          await this.$store.dispatch('otherContracts/create', this.newOtherContract)
          Vue.swal('Other contract uploaded successfully!', '', 'success').then(async () => {
            this.loading = true
            await this.$store.dispatch('users/fetchUserOtherContracts', { id: this.user.id })
            this.loading = false
          })
        } catch (e) {
          this.$toastr.error('', 'Error uploading a contract')
        }
        this.newOtherContract = {
          files_: [],
        }
      } else {
        Vue.swal('Please, upload a file first!', '', 'warning')
      }
    },
  },
}
</script>

<style>

</style>
